import { Outlet, useRouteError } from '@remix-run/react'
import { Layout } from '~/components/layout/layout'

export default function Page() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export const ErrorBoundary = () => {
  const error: any = useRouteError()

  return (
    <div className="overflow-auto rounded-lg border border-red-900 bg-red-50 p-4 text-red-900">
      <h1 className="text-xl">
        Hata <strong>{error.status || 500}</strong>
      </h1>
      <p>
        {error?.statusText} {error?.message} {error?.data?.error}
      </p>

      <pre className="overflow-x-auto pt-2 text-2xs">{error.stack}</pre>
      {/* <pre className="overflow-x-auto pt-2 text-2xs">
        {JSON.stringify(error)}
      </pre> */}
    </div>
  )
}
